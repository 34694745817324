import {useNavigate, useParams} from "react-router-dom";
import {FC, useContext, useEffect, useState} from "react";
import {BASE_URL} from "../../../services/constants";
import {getChannel, postUser} from "./fetchers";
import {UserProps, ChannelType} from "./type";
import ChannelForm from "../../../components/Forms/ChannelForm/ChannelForm";
import {AuthContext} from "../../../context/AuthContext";

const Channel: FC<UserProps> = ({ mode="create" }) => {
    const {setNotification} = useContext(AuthContext);
    const [channel, setChannel] = useState<ChannelType>({} as ChannelType);
    const navigate = useNavigate();
    const {id} = useParams();

    useEffect(() => {
        if (id) {
            (async () => {
                setChannel(await getChannel(id));
            })()
        }
    }, [id])

    const getUrl = () => {
        if (mode === "create") {
            return `${BASE_URL}/search/channels/create/`
        }
        return `${BASE_URL}/search/channels/${id}/edit/`
    }

    const onSubmit = async (data: ChannelType) => {
        const url = getUrl();
        try {
            await postUser(data, url);
            setNotification({
                type: "success",
                open: true,
                message: `Channel was successfully ${mode === "create" ? "created" : "edited"}`
            })
            navigate("/channels");
        } catch (err) {
            setNotification({
                type: "error",
                open: true,
                message: `Error occurred while ${mode === "create" ? "creating" : "editing"} channel`
            })
            console.log("Error creating related search");
        }
    }

    const formData = mode !== "create" && Object.keys(channel).length > 0
        ? channel as ChannelType
        : undefined

    return (
        <ChannelForm mode={mode} formData={formData} onSubmit={onSubmit} />
    )
}

export default Channel;
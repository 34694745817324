import React, {useContext, useEffect, useMemo, useState} from "react";
import dayjs, {Dayjs} from "dayjs";
import axios from "axios";
import {Link, useSearchParams} from "react-router-dom";
import {Box, Typography} from "@mui/material";
import {DataGrid, GridSingleSelectColDef, useGridApiRef} from "@mui/x-data-grid";
import LineGraphRecharts from "./components/LineGraphRecharts";
import DraftReports from "./components/DraftReports";
import DraftReportsModal from "./components/DraftReportsModal";
import {ReactComponent as SaveIconWhite} from "../../../assets/icons/saveIconWhite.svg";
import {ReactComponent as UpdateIconWhite} from "../../../assets/icons/updateIcon.svg";
import {generateId} from "../../../services/helpers";
import {AuthContext} from "../../../context/AuthContext";
import {Row} from "../../../components/TableConstructor/types";
import {thickRow} from "../../../components/TableConstructor/constants";
import {BASE_URL} from "../../../services/constants";
import DraftButton from "../../../UI/Buttons/Actions/DraftButton";
import TableFilterPanelStatistics from "../../../components/TableConstructor/components/TableFilterPanelStatistics";
import CustomButton from "../../../UI/Buttons/CustomButton";
import NothingFound from "../../../components/NothingFound";
import Loading from "../../../components/Loading/Loading";

export interface IPeriod {
    date_from: Dayjs | null;
    date_to: Dayjs | null;
    currency: string;
}

export interface IFilter {
    fieldId: string;
    key: string;
    option: string;
    value: string;
}
export interface IMetric {
    key: string;
    name: string;
    is_enabled: boolean;
    hint?: string;
}
export type IRequests = Record<string, any>;

const initialRequests = {
    periods: {
        date_from: dayjs().subtract(7, "day"),
        date_to: dayjs().add(-1, "day"),
        currency: "",
        periodHotKey: null
    },
    filters: [
        {
            fieldId: generateId(),
            key: "",
            option: "",
            value: "",
        },
    ],
    metrics: [],
    groups: [],
};
export interface IAggregation {
    label: string;
    keys: IMetric[];
}
export interface Previous {
    periods: IPeriod;
    metrics: IMetric[];
    groups: IMetric[];
    filters: IFilter[];
}

const CreateIconTsx = () => (
    <SaveIconWhite
        style={{
            color: "#fff",
            width: "1.25rem",
            height: "1.25rem",
            marginRight: ".5rem",
        }}
    />
);
const UpdateIconTsx = () => (
    <UpdateIconWhite
        style={{
            color: "#fff",
            width: "1.25rem",
            height: "1.25rem",
            marginRight: ".5rem",
        }}
    />
);
const ReportsPage = () => {
    const apiRef = useGridApiRef();
    const { setNotification } = useContext(AuthContext);
    const [requests, setRequests] = useState<IRequests>(initialRequests);
    const [headers, setHeaders] = useState<GridSingleSelectColDef[]>([]);
    const [rows, setRows] = useState<Row[] | null>(null);
    const [groupingLabel, setGroupingLabel] = useState<IMetric>({} as IMetric);
    const [tableActions, setTableActions] = useState<any>({
        period: true,
        is_report_filter: true,
        grouping: true,
        metrics: true,
        row_dense: false
    });
    const [loading, setLoading] = useState(false);
    const [allCounts, setAllCounts] = useState(0);
    const [isOpenDraftModal, setIsOpenDraftModal] = useState<boolean>(false);
    const [searchParams, setSearchParams] = useSearchParams();
    const [rowDense, setRowDense] = useState(thickRow);
    const [total, setTotal] = useState<Record<string, number>>({});

    const [buttonProps, setButtonProps] = useState<any>({
        props: [
            {
                svg: <CreateIconTsx />,
                label: "Create",
            },
            {
                svg: <UpdateIconTsx />,
                label: "Update",
            },
        ],
        state: 0,
    });

    useEffect(() => {
        const requestsJson = searchParams.get("requests");
        if (requestsJson) {
            const decodedRequests = JSON.parse(requestsJson);
            setRequests(decodedRequests);
        }
    }, []);

    const openOpenDraftModal = () => {
        setIsOpenDraftModal(true);
    };

    const mandatoryOptions = ["date__month", "date", "date__year", "week", "period"];
    const handleUpdate = async (signal: any = null) => {
        setLoading(true);
        const data = {
            ...requests,
            periods: {
                ...requests.periods,
                date_from: dayjs(requests.periods.date_from).format("YYYY-MM-DD"),
                date_to: dayjs(requests.periods.date_to).format("YYYY-MM-DD"),
            },
        };
        try {
            const res = await axios.post(`${BASE_URL}/report/create/`, data);
            const table = await res.data;
            const cols = table.headers.map((col: any) => ({
                field: col.key,
                headerName: col.label,
                type: col.type || "string",
                width: col.key === "id"
                    ? 50
                    : col.key === "date"
                        ? 100
                        : col.key === "channel__name_custom"
                            ? 100
                            : col.key === "cost_fb_sum"
                                ? 85
                                : col.key === "campaign_prefix"
                                    ? 120
                                    : 100,
                sortComparator: (value1: any, value2: any, params1: any, params2: any) => {
                    if (params1.id === "-" || params2.id === "-") {
                        return 0;
                    }
                    if (col.type === "string") {
                        return value1.localeCompare(value2);
                    }
                    return value1 - value2;
                },
                renderCell: (params: any) => {
                    if (params.colDef.field === "channel__name_custom") {
                        return <Link to={`/rs?channel=${params.formattedValue}`} target="_blank" style={{fontWeight: "600", textDecoration: "underline !important", color: "var(--main-purple-color)"}}>
                            {params.formattedValue}
                        </Link>
                    }
                    if (params.colDef.type === "number") {
                        return params.formattedValue.toLocaleString("ru-RU", {
                            maximumFractionDigits: 3,
                        });
                    }
                    return params.formattedValue;
                }
            }))
            const rows = [{
                id: "-", ...table.total,
            }, ...table.rows];
            setRows(rows);
            setHeaders(cols);
            setGroupingLabel(table.group);
            setAllCounts(table.all_counts);
            setTotal(table.total);
            const size = table.size || 50;
            const page = table.page || 1;
            setSearchParams({
                ...searchParams,
                page,
                size,
                requests: JSON.stringify(requests),
            });
        } catch (err: any) {
            setNotification({
                open: true,
                message: err.response.data.message,
                type: "error",
            });
        } finally {
            setLoading(false);
            setButtonProps((prev: any) => ({ ...prev, state: 1 }));
        }
    };

    const saveReportHandler = async (name: string) => {
        try {
            const data = {
                name,
                template: {
                    periods: requests.periods,
                    filters: requests.filters,
                    metrics: requests.metrics,
                    groups: requests.groups,
                },
            };
            const url = "report/templates/create/";
            const res = await axios.post(url, data);
            setNotification({
                open: true,
                message: res.data.message,
                type: "success",
            });
        } catch (err: any) {
            setNotification({
                open: true,
                message: err.response.data.message,
                type: "error",
            });
        }
    };

    const exportReportCSV = async () => {
        try {
            const data = {
                ...requests,
                periods: {
                    ...requests.periods,
                    date_from: dayjs(requests.periods.date_from).format("YYYY-MM-DD"),
                    date_to: dayjs(requests.periods.date_to).format("YYYY-MM-DD"),
                },
            };
            const url = "report/save/";
            const response = await axios.post(url, data, {
                responseType: "blob",
            });
            const reader = new FileReader();
            reader.onload = () => {
                const filename = response.headers["content-disposition"].split("filename=")[1];
                const link = window.URL.createObjectURL(
                    new Blob([JSON.parse(reader.result as string)], {
                        type: "application/json",
                    })
                );
                const a = document.createElement("a");
                a.href = link;
                a.download = filename || "test.json";
                a.click();
                URL.revokeObjectURL(link);
            };
            reader.readAsText(response.data);
        } catch (e: any) {
            console.error(e.response.data.message);
        }
    };

    useEffect(() => {
        const controller = new AbortController();
        let { sort = "" } = searchParams.has("requests") && JSON.parse(searchParams.get("requests") as string);
        sort = requests.sort || sort;
        if (requests.page || requests.size || sort) {
            (async () => handleUpdate(controller.signal))();
        }
        return () => controller.abort();
    }, [requests.sort, requests.page, requests.size]);

    const handleCloseDraftModal = () => {
        setIsOpenDraftModal(false);
    };
    return (
        <>
            <Box sx={{display: "flex", flexWrap: "wrap", justifyContent: "space-between", alignItems: "center", mb: "1.5rem"}}>
                <Typography sx={{fontWeight: "500", fontSize: {xs: "1.25rem", md: "1.5rem"}}} component="h1">
                    Statistics
                </Typography>
                <Box sx={{display: "flex", width: {xs: "100%", md: "auto"}, gap: ".25rem"}}>
                    <DraftButton action={openOpenDraftModal} />
                    <CustomButton
                        style={{width: {xs: "100%", md: "auto"}}}
                        mode={requests.groups.map((metric: IMetric) => metric.key).some((key: string) => mandatoryOptions.includes(key)) ? "warning" : "dark"}
                        label={buttonProps.props[buttonProps.state].label}
                        onChange={handleUpdate}
                        disabled={!requests.groups.map((metric: IMetric) => metric.key).some((key: string) => mandatoryOptions.includes(key))}
                    />
                </Box>
            </Box>
            <TableFilterPanelStatistics
                rowDense={rowDense}
                setRowDense={setRowDense}
                loading={loading}
                rows={rows || []}
                tableActions={tableActions}
                request={requests}
                setRequest={setRequests}
                isStatistics
            />
            {rows &&
                (headers.length > 0 && rows.length > 0 ? (
                    <>
                        <DraftReports loading={loading} saveReportHandler={saveReportHandler} exportReportCSV={exportReportCSV} />
                        <LineGraphRecharts loading={loading} requests={requests} rows={rows.slice(1)} headers={headers} groupingLabel={groupingLabel} />
                        <DataGrid
                            sx={{
                                "@media (hover: none)": {
                                    "&& .MuiDataGrid-menuIcon": {
                                        width: 0,
                                        visibility: "hidden",
                                    }
                                },
                                "&& .MuiDataGrid-columnHeader--sorted .MuiDataGrid-menuIcon": {
                                    width: "auto",
                                    visibility: "visible",
                                },
                                "&& .MuiDataGrid-columnHeader": {
                                    p: {xs: "0 2px", md: "0 10px"}
                                }

                            }}
                            apiRef={apiRef}
                            rows={rows}
                            columns={headers}
                            initialState={{
                                pagination: {
                                    paginationModel: { page: 0, pageSize: 15 },
                                },
                            }}
                            pageSizeOptions={[15, 25, 50, 100]}
                            autoHeight
                            loading={loading}
                            hideFooterSelectedRowCount
                            getRowClassName={params => params.id === "-" ? "bold-row" : ""}
                        />
                    </>
                ) : (
                    <NothingFound />
                ))}
            {loading && !rows && (
                <Box
                    sx={{
                        height: "300px",
                        display: "flex",
                    }}
                >
                    <Loading />
                </Box>
            )}
            <DraftReportsModal setRequests={setRequests} open={isOpenDraftModal} handleClose={handleCloseDraftModal} />
        </>
    );
};

export default ReportsPage;

import DeviceHubIcon from "@mui/icons-material/DeviceHub";
import BrushIcon from "@mui/icons-material/Brush";
import CategoryIcon from "@mui/icons-material/Category";
import AssessmentIcon from "@mui/icons-material/Assessment";
import DashboardIcon from "@mui/icons-material/Dashboard";
import {ReactComponent as RSIcon} from "../assets/icons/rs.svg";
import {ReactComponent as UsersIcon} from "../assets/icons/users.svg";

const BASE_URL = "https://afs-backend.tds.bid";

const navLinks = [
    {
        label: "Dashboard",
        key: "dashboard",
        href: "/dashboard",
        Icon: DashboardIcon
    },
    {
        label: "Related searches",
        key: "related_searchs",
        href: "/related_searchs",
        Icon: RSIcon
    },
    {
        label: "Users",
        key: "users",
        href: "/users",
        permission: "user_view",
        Icon: UsersIcon
    },
    {
        label: "Channels",
        key: "channels",
        href: "/channels",
        permission: "channel_view",
        Icon: DeviceHubIcon
    },
    {
        label: "Ad styles",
        key: "ad_styles",
        href: "/ad_styles",
        permission: "style_view",
        Icon: BrushIcon
    },
    {
        label: "Categories",
        key: "ad_category",
        href: "/ad_category",
        permission: "category_view",
        Icon: CategoryIcon
    },
    {
        label: "Statistics (DEV)",
        key: "statistics",
        href: "/statistics",
        Icon: AssessmentIcon
    }
]


export {
    BASE_URL,
    navLinks,
}
export default {
    site: {
        title: "Site",
        defaultValue: "",
        hint: "Choose you site",
        order: 0,
        rules: {
            required: {
                value: true,
                message: "Field is required"
            }
        },
        options: [""],
        type: "select",
        typeSend: "string",
        float: "left",
    },
    name: {
        title: "Ad Style name",
        defaultValue: "",
        hint: "Write ad style name",
        order: 1,
        rules: {
            required: {
                value: true,
                message: "Ad style name is required"
            }
        },
        type: "string",
        typeSend: "string",
        float: "left",
    },
    style_id: {
        title: "Style unique ID",
        defaultValue: "",
        hint: "Write style ID from Adsense",
        order: 2,
        rules: {
            required: {
                value: true,
                message: "Style ID is required"
            }
        },
        type: "string",
        typeSend: "string",
        float: "left",
    },
    available_for: {
        title: "Choose users available for Ad Style",
        defaultValue: "",
        hint: "Choose channels for user",
        order: 4,
        type: "multiple-checkboxes",
        typeSend: "string",
        float: "left",
    },
}

import {useNavigate} from "react-router-dom";
import React, {useContext} from "react";
import IconButton from "@mui/material/IconButton";
import MoreVertIcon from "@mui/icons-material/MoreVert";
import {Menu, MenuItem} from "@mui/material";
import axios from "axios";
import {AuthContext} from "../../context/AuthContext";

const initialCustomMenuCell = {
    actions: {
        view: true,
        edit: true,
        delete: true,
        copy: false
    },
    cellParams: {},
    setIsLoading: (value: boolean) => {}
}

const CustomMenuCell = ({actions=initialCustomMenuCell.actions, cellParams=initialCustomMenuCell.cellParams, setIsLoading=initialCustomMenuCell.setIsLoading}: any) => {
    const {setNotification} = useContext(AuthContext);
    const navigate = useNavigate();
    const [anchorEl, setAnchorEl] = React.useState(null);

    const postAction = async (key: string, id: string) => {
        const system = window.location.pathname.split("/")[1]
        try {
            setIsLoading(true)
            await axios.get(`/search/${system}/${id}/${key}/`);
            setNotification({
                type: "success",
                open: true,
                message: `${key} succeed! WILL RELOAD NOW`
            })
            setIsLoading(false)
            setTimeout(() => {
                window.location.reload();
            }, 1000);
        } catch (error) {
            setNotification({
                type: "error",
                open: true,
                message: `Error occurred while ${key}, try later!`
            })
        }
    }

    const handleClick = (event: any) => {
        setAnchorEl(event.currentTarget);
    };

    const handleClose = () => {
        setAnchorEl(null);
    };

    const handleOpen = () => {
        navigate(`view/${cellParams.id}`);
        handleClose();
    };

    const handleEdit = () => {
        navigate(`edit/${cellParams.id}`);
        handleClose();
    };

    const handleDelete = async () => {
        console.log(cellParams)
        handleClose();
        await postAction("delete", cellParams.id);
    }

    const handleCopy = async () => {
        handleClose();
        await postAction("copy", cellParams.id);
    }

    return (
        <div>
            <IconButton onClick={handleClick}>
                <MoreVertIcon />
            </IconButton>
            <Menu
                anchorEl={anchorEl}
                open={Boolean(anchorEl)}
                onClose={handleClose}
            >
                { actions.view &&
                    <MenuItem onClick={handleOpen}>View</MenuItem>
                }
                { actions.edit &&
                    <MenuItem onClick={handleEdit}>Edit</MenuItem>
                }
                { actions.delete &&
                    <MenuItem onClick={handleDelete}>Delete</MenuItem>
                }
                { actions.copy &&
                    <MenuItem onClick={handleCopy}>Copy</MenuItem>
                }
            </Menu>
        </div>
    );
};

export default CustomMenuCell;

export default {
    site: {
        title: "Site",
        defaultValue: "",
        hint: "Choose you site",
        order: 0,
        rules: {
            required: {
                value: true,
                message: "Field is required"
            }
        },
        options: [""],
        type: "select",
        typeSend: "string",
        float: "left",
    },
    name: {
        title: "Category name",
        defaultValue: "",
        hint: "Write category name",
        order: 1,
        rules: {
            required: {
                value: true,
                message: "Category name is required"
            }
        },
        type: "string",
        typeSend: "string",
        float: "left",
    }
}

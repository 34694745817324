import {useNavigate, useParams} from "react-router-dom";
import {FC, useContext, useEffect, useState} from "react";
import {BASE_URL} from "../../../services/constants";
import {getCategory, postAdStyle} from "./fetchers";
import {CategoryType, UserProps} from "./type";
import CategoryForm from "../../../components/Forms/CategoryForm/CategoryForm";
import {AuthContext} from "../../../context/AuthContext";

const Category: FC<UserProps> = ({ mode="create" }) => {
    const {setNotification} = useContext(AuthContext);
    const [user, setUser] = useState<CategoryType>({} as CategoryType);
    const navigate = useNavigate();
    const {id} = useParams();

    useEffect(() => {
        if (id) {
            (async () => {
                setUser(await getCategory(id));
            })()
        }
    }, [id])

    const getUrl = () => {
        if (mode === "create") {
            return `${BASE_URL}/search/ad_category/create/`
        }
        return `${BASE_URL}/search/ad_category/${id}/edit/`
    }

    const onSubmit = async (data: CategoryType) => {
        const url = getUrl();
        try {
            await postAdStyle(data, url);
            setNotification({
                type: "success",
                open: true,
                message: `Category was successfully ${mode === "create" ? "created" : "edited"}`
            })
            navigate("/ad_category");
        } catch (err) {
            setNotification({
                type: "error",
                open: true,
                message: `Error occurred while ${mode === "create" ? "creating" : "editing"} category`
            })
            console.log("Error creating category");
        }
    }

    const formData = mode !== "create" && Object.keys(user).length > 0
        ? user as CategoryType
        : undefined

    return (
        <CategoryForm mode={mode} formData={formData} onSubmit={onSubmit} />
    )
}

export default Category;